<script src="../customer/defaults/defaultsConfig.js"></script>
<template>
  <sub-menu-component
    :menu-items="menuItems"
  >
    <router-view @updateMenu="updateMenu" />
  </sub-menu-component>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import organizationModule from '@/store/organization'
import generalMenuItems from '@/views/components/sub-menu/settings-sub-menu/generals'

export default {
  name: 'General',
  setup() {
    const menuItems = ref()
    const MODULE_NAME = 'users'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, organizationModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      menuItems,
    }
  },
  methods: {
    updateMenu(active) {
      this.menuItems = generalMenuItems(active).menuItems
    },
  },
}
</script>

<style scoped>

</style>
