// eslint-disable-next-line import/prefer-default-export
export const SETTING_GENERAL_ORGANIZATION = {
  action: 'view',
  resource: 'Setup Organization',
}
export const SETTING_GENERAL_INTEGRATIONS = {
  action: 'view',
  resource: 'Integrations',
}

export const SETTING_GENERAL_PAYMENT_TERMS = {
  action: 'view',
  resource: 'Payment Terms',
}

export const SETTING_GENERAL_PAYMENT_OPTIONS = {
  action: 'view',
  resource: 'Payment Options',
}
