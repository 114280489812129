import {
  SETTING_GENERAL_ORGANIZATION,
  SETTING_GENERAL_INTEGRATIONS,
  SETTING_GENERAL_PAYMENT_TERMS,
  SETTING_GENERAL_PAYMENT_OPTIONS,
} from '../meta/settings-sub-menu/generals'

// eslint-disable-next-line import/prefer-default-export
export default function generalMenuItems(active, params = {}) {
  const menuItems = [
    {
      name: 'settings-general-organization',
      layout: 'Organization',
      ...SETTING_GENERAL_ORGANIZATION,
    },
    {
      name: 'settings-taxes',
      layout: 'Taxes',
    },
    {
      name: 'settings-payment-terms',
      layout: 'Payment Terms',
      ...SETTING_GENERAL_PAYMENT_TERMS,
    },
    {
      name: 'settings-payment-methods',
      layout: 'Payment Methods',
      ...SETTING_GENERAL_PAYMENT_OPTIONS,
    },
    {
      name: 'settings-general-integrations',
      layout: 'Integrations',
      ...SETTING_GENERAL_INTEGRATIONS,
    },
    // {
    //   name: 'settings-general-translations',
    //   layout: 'Translations',
    // },
  ]
  menuItems.forEach(item => {
    // eslint-disable-next-line no-param-reassign
    item.active = item.name === active
    // eslint-disable-next-line no-param-reassign
    item.route = { name: item.name, params }
  })
  return {
    menuItems,
  }
}
